<template>
  <iframe
    class="pdfContainer"
    :src="'pdf/web/viewer.html?file=' + source">
  </iframe>
</template>

<script>
  export default {
    props: [
      'category',
    ],
    computed: {
      source() {
        return this.category?.media?.links?.find(l => l.rel === 'download')?.href
      }
    },
    async created() {
      if (!this.source) {
        if (this.$route.params.slug) {
          return this.go(`/${this.$route.params.slug}/auth/login`)
        }
        return this.go('/auth/login')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pdfContainer {
    @apply
      w-full
      h-full;
  }
</style>